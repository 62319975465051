import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { customerMethods } from "../../api/apiMethods";
import { genericErrorConfig } from "../../common/exceptions";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import { ModalContext } from "../../components/Modal";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { routes } from "../../routes";
import { Logger } from "../../utils/logger/Logger";

const MatkontoCallbackView = () => {
    const municipalityId = useSelectedMunicipalityId();
    const [searchParams] = useSearchParams();
    const { setModalOptions } = useContext(ModalContext);

    useEffect(() => {
        const matkontoVerification = async () => {
            const transactionCode = searchParams.get("code");
            const unitId = searchParams.get("unitId");
            const customerId = searchParams.get("customerId");

            if (!transactionCode || !unitId || !customerId) {
                throw new Error("Matkonto - Missing required query parameters");
            }

            try {
                const response = await customerMethods(
                    municipalityId
                ).verifyMatkonto(unitId, customerId, transactionCode);

                if (response) {
                    window.location.href = `${routes.customerById(
                        municipalityId,
                        customerId
                    )}`;
                }
            } catch (error: any) {
                Logger.error(error);
                setModalOptions(
                    genericErrorConfig(
                        () => setModalOptions({ visible: false }),
                        "Kunde inte verify Matkonto."
                    )
                );
            }
        };

        matkontoVerification();
    }, [municipalityId, searchParams, setModalOptions]);

    return (
        <Container>
            <SubHeader title="Kunde inte verifiera Matkonto." />
            <Plate>
                <Loader />
            </Plate>
        </Container>
    );
};

export default MatkontoCallbackView;
