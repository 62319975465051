import React from "react";
import styled from "styled-components";
import colors from "../../common/colors";
import { breakpoint, fontSize, space } from "../../common/variables";
import Container from "../../components/Container";
import Plate from "../../components/Plate";

const MunicipalityBaseView = (
    props: React.PropsWithChildren<{ title: string; subTitle?: string }>
) => {
    return (
        <Container size="small">
            <Plate padding="large">
                <Content>
                    <Title>{props.title}</Title>
                    {props.subTitle && <Subtitle>{props.subTitle}</Subtitle>}
                    {props.children}
                </Content>
            </Plate>
        </Container>
    );
};

const Content = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${space.small};
`;

const Title = styled.h1`
    font-family: "CoopNew-Black";
    font-size: ${fontSize.xxLarge};
    line-height: ${fontSize.xxLarge};
    color: ${colors.black};
    margin: 0;
    @media ${breakpoint.small} {
        font-size: ${fontSize.xxxLarge};
        line-height: ${fontSize.xxxLarge};
    }
`;

const Subtitle = styled.p`
    margin: 0;
    font-size: ${fontSize.medium};
    color: ${colors.black};
    line-height: ${fontSize.medium};
`;

export default MunicipalityBaseView;
