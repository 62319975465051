import { useMutation, useQueryClient } from "@tanstack/react-query";
import { customerMethods } from "../../api/apiMethods";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../../queries/queryKeys";
import { Logger } from "../../utils/logger/Logger";

export const useCustomerMatkontoDelete = (options: {
    onSuccess?: () => void;
    onError: () => void;
}) => {
    const municipalityId = useSelectedMunicipalityId();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({
            unitId,
            customerId,
            tokenId,
        }: {
            unitId: string;
            customerId: string;
            tokenId: string;
        }) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                await customerMethods(municipalityId).deleteMatkontoCard(
                    unitId,
                    customerId,
                    tokenId
                );
            } catch (err) {
                throw err;
            }
        },
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.customer.byId(
                    variables.customerId,
                    municipalityId
                ),
            });
            options.onSuccess?.();
        },
        onError: async (response: Response) => {
            Logger.error(response);

            let jsonError;
            try {
                jsonError = await response.json();
                Logger.error(jsonError);
            } catch {}

            options.onError();
        },
    });

    return mutation;
};
