import { useMutation, useQueryClient } from "@tanstack/react-query";
import { unitMethods } from "../api/apiMethods";
import { TimeRestriction } from "../common/types";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queries/queryKeys";
import { UnitInput } from "../views/UnitView/unitTypes";

export const useUpdateUnitMutation = (options: {
    onSuccess?: (data: string) => void;
    onError?: (error: Error) => void;
}) => {
    const municipalityId = useSelectedMunicipalityId();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (params: {
            input: UnitInput;
            unitId?: string;
            selectedTimeFrames: TimeRestriction[];
        }) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            if (!params.unitId) {
                throw new Error("No unit id");
            }

            try {
                await unitMethods(municipalityId).updateUnitRestrictions(
                    params.selectedTimeFrames,
                    params.unitId
                );

                await unitMethods(municipalityId).updateUnit(
                    params.input,
                    params.unitId
                );

                return params.unitId;
            } catch (err) {
                throw err;
            }
        },
        onSuccess: (unitId) => {
            options.onSuccess?.(unitId);
            queryClient.invalidateQueries({
                queryKey: queryKeys.unit.all(municipalityId),
            });
            queryClient.invalidateQueries({
                queryKey: queryKeys.unit.byId(municipalityId, unitId),
            });
        },
        onError: options.onError,
    });

    return mutation;
};
