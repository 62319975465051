import React, { useState } from "react";
import Modal, { ModalContext, ModalOptions } from "./Modal";

const ModalContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const [modalOptions, setModalOptions] = useState<ModalOptions>({
        visible: false,
    });

    return (
        <ModalContext.Provider value={{ modalOptions, setModalOptions }}>
            {children}
            <Modal {...modalOptions} />
        </ModalContext.Provider>
    );
};

export default ModalContextProvider;
