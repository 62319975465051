import { useMutation, useQueryClient } from "@tanstack/react-query";
import { customerMethods } from "../../api/apiMethods";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../../queries/queryKeys";
import { Logger } from "../../utils/logger/Logger";

export const useDeleteCustomerMutation = (options: {
    onSuccess: () => void;
    onError: (error: any) => void;
}) => {
    const municipalityId = useSelectedMunicipalityId();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({
            unitId,
            customerId,
        }: {
            unitId?: string;
            customerId?: string;
        }) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            if (!unitId) {
                throw new Error("No unit id");
            }

            if (!customerId) {
                throw new Error("No customer id");
            }

            try {
                await customerMethods(municipalityId).deleteCustomer(
                    unitId,
                    customerId
                );
            } catch (err) {
                throw err;
            }
        },
        onSuccess: options.onSuccess,
        onError: async (error: Response) => {
            Logger.error(error);

            let parsedError;
            try {
                parsedError = await error.json();
            } catch {}

            options.onError(parsedError);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.customer.full(municipalityId),
            });
        },
    });

    return mutation;
};
