import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { genericErrorConfig } from "../../common/exceptions";
import { MatkontoTmpInfoProvider } from "../../common/helpers/matkontoTmpInfoProider";
import Loader from "../../components/Loader";
import { ModalContext } from "../../components/Modal";
import { routes } from "../../routes";
import { Logger } from "../../utils/logger/Logger";

const MatkontoCallbackRedirect = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { setModalOptions } = useContext(ModalContext);

    const transactionCode = searchParams.get("code");

    useEffect(() => {
        try {
            if (!transactionCode) {
                throw new Error("Matkonto - Missing transaction code");
            }
            const verifyCustomerInfo = MatkontoTmpInfoProvider.get();
            if (!verifyCustomerInfo) {
                throw new Error("Matkonto - Missing verify customer info");
            }

            const params = new URLSearchParams({
                code: transactionCode,
                customerId: verifyCustomerInfo.customerId,
                unitId: verifyCustomerInfo.unitId,
            });

            MatkontoTmpInfoProvider.remove();

            navigate(
                `${routes.matkontoVerify(
                    verifyCustomerInfo.municipalityId
                )}?${params.toString()}`,
                { replace: true }
            );
        } catch (error: any) {
            Logger.error(error);
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde inte verify Matkonto."
                )
            );
            navigate("/");
        }
    }, [navigate, transactionCode, setModalOptions]);

    return <Loader fullHeight />;
};

export default MatkontoCallbackRedirect;
