import React, { useState } from "react";
import { MunicipalityOrg } from "../common/types";
import { Combobox, ComboboxOption } from "./Combobox";

interface MunicipalityComboboxProps {
    municipalities: MunicipalityOrg[];
    onSelect: (municipality: MunicipalityOrg) => void;
    defaultValue?: MunicipalityOrg;
}

const MunicipalityCombobox = (props: MunicipalityComboboxProps) => {
    const [query, setQuery] = useState("");

    const sortedMunicipalities = props.municipalities.sort((a, b) =>
        a.name.localeCompare(b.name, "sv")
    );

    const municipalities =
        query === ""
            ? sortedMunicipalities
            : sortedMunicipalities.filter((municipalityOrg) =>
                  municipalityOrg.name
                      .toLowerCase()
                      .replace(/\s+/g, "")
                      .includes(query.toLowerCase().replace(/\s+/g, ""))
              );

    return (
        <Combobox<MunicipalityOrg>
            placeholder="Sök eller välj organisation "
            theme="whiteGreenDark"
            size={48}
            defaultValue={props.defaultValue}
            query={query}
            onValueChange={props.onSelect}
            displayName={(value) => value.name}
            onQueryChange={(value) => setQuery(value)}
            itemCount={municipalities.length}>
            {municipalities.map((store) => (
                <ComboboxOption value={store} key={store.code}>
                    {store.name}
                </ComboboxOption>
            ))}
        </Combobox>
    );
};

export default MunicipalityCombobox;
