import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../common/colors";
import mixins from "../common/mixins";
import { MunicipalityOrg } from "../common/types";
import { breakpoint, fontSize, space } from "../common/variables";
import { routes } from "../routes";
import MunicipalitySelector from "./MunicipalitySelector";

const Navigation = ({
    items,
    allowedMunicipalities,
    municipalityId,
}: {
    items: { title: string; path: string }[];
    allowedMunicipalities?: MunicipalityOrg[];
    municipalityId?: string;
}) => {
    const navigate = useNavigate();

    const hasMoreThanOneMunicipality =
        allowedMunicipalities && allowedMunicipalities.length > 1;

    return (
        <NavHeader centered={!hasMoreThanOneMunicipality}>
            {hasMoreThanOneMunicipality && (
                <Selector>
                    <MunicipalitySelector
                        municipalities={allowedMunicipalities}
                        municipalityId={municipalityId}
                        onSelect={(municipality: MunicipalityOrg) =>
                            navigate(`${routes.customers(municipality.code)}`)
                        }
                    />
                </Selector>
            )}
            {items.length > 1 && (
                <Wrapper>
                    <List>
                        {items.map((item, i) => (
                            <Item key={i}>
                                <NavLink
                                    to={item.path}
                                    style={({ isActive }) => {
                                        if (isActive) {
                                            return {
                                                color: colors.green,
                                            };
                                        }
                                        return undefined;
                                    }}>
                                    {item.title}
                                </NavLink>
                            </Item>
                        ))}
                    </List>
                </Wrapper>
            )}
            {hasMoreThanOneMunicipality && <Spacer />}
        </NavHeader>
    );
};

export default Navigation;

const NavHeader = styled.div<{ centered: boolean }>`
    display: flex;
    justify-content: ${({ centered }) =>
        centered ? "center" : "space-between"};
    align-items: center;
    padding-bottom: 16px;
    flex-wrap: wrap;
    gap: 20px;
    &:empty {
        display: none;
    }
`;

const Selector = styled.div`
    width: 100%;
    @media ${breakpoint.small} {
        max-width: 250px;
    }
`;

const Spacer = styled.div`
    width: 100%;
    max-width: 250px;
    display: none;
    @media ${breakpoint[1024]} {
        display: flex;
    }
`;

const Wrapper = styled.nav`
    background-color: ${colors.white};
    transition: transform ${mixins.transitions.default};
    width: 100%;
    @media ${breakpoint.small} {
        width: inherit;
    }
`;

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: ${space.small};
`;

const Item = styled.li`
    font-size: ${fontSize.medium};
    font-weight: 700;
`;
