import { useMutation, useQueryClient } from "@tanstack/react-query";
import { customerMethods } from "../../api/apiMethods";
import { FieldError, HTTPError } from "../../common/types";
import { isHybrisValidationError } from "../../common/validations";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../../queries/queryKeys";
import { Logger } from "../../utils/logger/Logger";
import { CustomerFormData } from "../../views/CustomerView/CustomerView.hooks";
import {
    extractFieldErrorsFromHybrisErrorAsArray,
    mapCustomerFormToUpdateBillingRequest,
    mapCustomerFormToUpdateRequest,
    mapHybrisCustomerBillingErrorField,
    mapHybrisCustomerInfoErrorField,
} from "../../views/CustomerView/customerDetailsConfig";

class CustomerFormErrorWithType extends Error {
    innerError: unknown;
    type: "billing" | "restrictions" | "payment" | "customer";

    constructor(
        message: string,
        type: "billing" | "restrictions" | "payment" | "customer",
        innerError: unknown
    ) {
        super(message);
        this.innerError = innerError;
        this.type = type;
    }
}

export const useCustomerFormMutation = (options: {
    onSuccess?: () => void;
    onGenericError: (args: {
        message: string;
        jsonError: unknown | undefined;
    }) => void;
    onFieldValidationError: (args: {
        fieldErrors?: FieldError[];
        message: string;
        jsonError: unknown | undefined;
    }) => void;
}) => {
    const municipalityId = useSelectedMunicipalityId();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async ({
            formData,
            billingId,
            oldUnitId,
            customerId,
        }: {
            formData: CustomerFormData;
            billingId?: string;
            oldUnitId?: string;
            customerId: string;
        }) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            const mappedBillingModel =
                mapCustomerFormToUpdateBillingRequest(formData);

            if (
                mappedBillingModel &&
                (formData.paymentInfoId === "intrum" ||
                    formData.paymentInfoId === "collectiveinvoice")
            ) {
                try {
                    // CREATE or UPDATE BILLING ADDRESS
                    if (billingId) {
                        await customerMethods(
                            municipalityId
                        ).updateCustomerBilling(
                            mappedBillingModel,
                            formData.unit.id,
                            customerId,
                            billingId
                        );
                    } else {
                        await customerMethods(
                            municipalityId
                        ).createCustomerBilling(
                            mappedBillingModel,
                            formData.unit.id,
                            customerId
                        );
                    }
                } catch (e) {
                    throw new CustomerFormErrorWithType(
                        "Kunde inte spara uppgifterna",
                        "billing",
                        e
                    );
                }
            }

            // UPDATE RESTRICTIONS
            try {
                await customerMethods(
                    municipalityId
                ).updateCustomerRestrictions(
                    formData.timeRestrictions.map(
                        (restriction) => restriction.extraData
                    ),
                    formData.unit.id,
                    customerId
                );
            } catch (e) {
                throw new CustomerFormErrorWithType(
                    "Kunde inte spara leveranstid",
                    "restrictions",
                    e
                );
            }

            // UPDATE PAYMENT INFO
            try {
                await customerMethods(municipalityId).updateCustomerPaymentInfo(
                    formData.paymentInfoId,
                    formData.unit.id,
                    customerId
                );
            } catch (e) {
                throw new CustomerFormErrorWithType(
                    "Kunde inte spara betalningsmetod",
                    "payment",
                    e
                );
            }

            // UPDATE CUSTOMER INFO
            if (oldUnitId) {
                try {
                    await customerMethods(municipalityId).updateCustomer(
                        mapCustomerFormToUpdateRequest(formData),
                        oldUnitId, // if new unit is picked, we need to keep the old one in API request URL and new one as body param
                        customerId
                    );
                } catch (e) {
                    throw new CustomerFormErrorWithType(
                        "Kunde inte spara uppgifterna",
                        "customer",
                        e
                    );
                }
            }

            return "success";
        },
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries({
                queryKey: queryKeys.customer.byId(
                    variables.customerId,
                    municipalityId
                ),
            });
            options.onSuccess?.();
        },
        onError: async (error) => {
            Logger.error(error);

            if (error instanceof CustomerFormErrorWithType) {
                const jsonError = await tryParseResponse(error.innerError);
                switch (error.type) {
                    case "billing": {
                        if (
                            isHybrisValidationError(jsonError) &&
                            jsonError.errors.length > 0 &&
                            (error.innerError as Response).status ===
                                HTTPError._400_BAD_REQUEST
                        ) {
                            const fieldErrors: FieldError[] =
                                extractFieldErrorsFromHybrisErrorAsArray(
                                    jsonError,
                                    mapHybrisCustomerBillingErrorField
                                );
                            options.onFieldValidationError({
                                fieldErrors,
                                message: error.message,
                                jsonError,
                            });
                            return;
                        }

                        options.onFieldValidationError({
                            message: error.message,
                            jsonError,
                        });

                        break;
                    }
                    case "customer": {
                        if (
                            isHybrisValidationError(jsonError) &&
                            jsonError.errors.length > 0 &&
                            (error.innerError as Response).status ===
                                HTTPError._400_BAD_REQUEST
                        ) {
                            const fieldErrors: FieldError[] =
                                extractFieldErrorsFromHybrisErrorAsArray(
                                    jsonError,
                                    mapHybrisCustomerInfoErrorField
                                );
                            options.onFieldValidationError({
                                fieldErrors,
                                message: error.message,
                                jsonError,
                            });
                            return;
                        }

                        options.onFieldValidationError({
                            message: error.message,
                            jsonError,
                        });

                        break;
                    }
                    case "restrictions":
                        options.onGenericError({
                            message: error.message,
                            jsonError: tryParseResponse(error),
                        });
                        break;
                    case "payment":
                        options.onGenericError({
                            message: error.message,
                            jsonError: tryParseResponse(error),
                        });
                        break;
                    default:
                        options.onGenericError({
                            message: "Kunde inte spara uppgifterna",
                            jsonError: tryParseResponse(error),
                        });
                        break;
                }
            } else {
                options.onGenericError({
                    message: "Kunde inte spara uppgifterna",
                    jsonError: tryParseResponse(error),
                });
            }
        },
    });

    return mutation;
};

const tryParseResponse = async (error: unknown) => {
    try {
        error = await (error as Response).json();
        Logger.error(error);
        return error;
    } catch {
        return undefined;
    }
};
