import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { unitMethods } from "../../api/apiMethods";
import { AuthenticatedUserContext } from "../../contexts/AuthenticatedUserContext";
import { useQueryErrorHandling } from "../../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queryKeys";

export const useAllUnitsQuery = () => {
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.unit.all(municipalityId),
        queryFn: () => {
            if (!authUserData) {
                throw new Error("No auth user data");
            }

            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                return unitMethods(municipalityId).getAllUnits();
            } catch (error: any) {
                throw new Error("Could not get all units");
            }
        },
        select: ({ municipalityUnit: units }) => {
            if (authUserData?.municipalityRole === "ADMIN") {
                return units;
            }

            if (authUserData?.municipalityRole === "ORDERER") {
                // this API endpoint returns all possible units, not just units for current user, so we must filter others out
                return units.filter((unit) =>
                    authUserData.municipalityAuthorisedUnitCodesMap[
                        municipalityId
                    ]?.includes(unit.code)
                );
            }

            return [];
        },
        enabled: !!authUserData,
        staleTime: Infinity,
    });

    useQueryErrorHandling(query, "Kunde inte hämta enheter.");

    return query;
};
