import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { AuthContext } from "../auth/authProvider";
import assets from "../common/assets";
import colors from "../common/colors";
import mixins from "../common/mixins";
import { dimension, fontSize, space } from "../common/variables";
import Container from "../components/Container";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import Button from "./Button";
import DropdownMenu from "./DropdownMenu";

interface HeaderProps {
    blur?: boolean;
    municipalityName?: string;
    position: "fixed" | "sticky";
}

const Header = (props: React.PropsWithChildren<HeaderProps>) => {
    const { signout } = useContext(AuthContext);
    const { authUserData } = useContext(AuthenticatedUserContext);

    return (
        <Wrapper
            blur={props.blur ? props.blur : undefined}
            position={props.position}>
            <Container>
                <Content>
                    <Link to="/">
                        <Logo src={assets.logo} />
                    </Link>
                    {props.municipalityName && (
                        <Title>{props.municipalityName}</Title>
                    )}
                    <DropdownMenu
                        headerContent={
                            authUserData && (
                                <Menu>
                                    <Icon>{assets.user}</Icon>
                                    <MenuTitle>
                                        {authUserData.firstName?.length
                                            ? authUserData.firstName
                                            : "Mitt konto"}
                                    </MenuTitle>
                                </Menu>
                            )
                        }>
                        <Button
                            size="small"
                            theme="inverted"
                            onClick={() => signout()}
                            title="Logga ut"
                        />
                    </DropdownMenu>
                </Content>
                {props.children}
            </Container>
        </Wrapper>
    );
};

export default Header;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const Wrapper = styled.header<{ blur?: boolean; position: "fixed" | "sticky" }>`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    position: ${({ position }) => (position === "sticky" ? "sticky" : "fixed")};
    margin-bottom: ${({ position }) => position === "sticky" && "28px"};
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${colors.border};
    z-index: 9;
    transition: filter ${mixins.transitions.large};
    filter: ${({ blur }: { blur?: boolean }) => (blur ? "blur(4px)" : "none")};
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: ${dimension.headerHeight};
    background-color: ${colors.white};
    z-index: 1;
`;

const Logo = styled.img`
    height: 40px;
`;

const Title = styled.h1`
    font-size: ${fontSize.large};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    display: inline-block;
    animation: ${fadeIn} ${mixins.transitions.large} forwards;
`;

const Menu = styled.div`
    display: flex;
    animation: ${fadeIn} ${mixins.transitions.large} forwards;
    align-items: center;
`;

const MenuTitle = styled.span`
    margin-left: ${space.xSmall};
    font-size: ${fontSize.small};
`;

const Icon = styled.span`
    display: inline-block;
    fill: ${colors.black};
    height: 20px;
    width: 20px;
`;
