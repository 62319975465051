import { useQuery } from "@tanstack/react-query";
import { unitMethods } from "../../api/apiMethods";
import { useQueryErrorHandling } from "../../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queryKeys";

export const useUnitRestrictionsQuery = (unitId: string | undefined) => {
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.unit.restrictions(municipalityId, unitId),
        queryFn: async () => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            if (!unitId) {
                throw new Error("Missing unit id");
            }

            try {
                return unitMethods(municipalityId).getUnitRestrictions(unitId);
            } catch (error) {
                throw new Error("Could not get unit restrictions data");
            }
        },
        select: (response) => response.homeTimeWindowRestrictions || [],
        enabled: !!unitId,
        staleTime: 1000 * 60 * 5,
    });

    useQueryErrorHandling(
        query,
        "Kunde inte hämta tidsfönster för denna enhet."
    );

    return query;
};
