import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { unitMethods } from "../../api/apiMethods";
import { AuthenticatedUserContext } from "../../contexts/AuthenticatedUserContext";
import { useQueryErrorHandling } from "../../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queryKeys";

export const useUnitReplacementWindowsQuery = (unitId?: string) => {
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const fetchAuthorized =
        authUserData && authUserData.municipalityRole === "ADMIN";

    const query = useQuery({
        queryKey: queryKeys.municipality.restrictions(municipalityId),
        queryFn: () => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            if (!unitId) {
                throw new Error("Missing unit id");
            }

            try {
                return unitMethods(municipalityId).getUnitReplacementWindows(
                    unitId
                );
            } catch (error) {
                throw new Error("Could not get unit replacement windows.");
            }
        },
        enabled: fetchAuthorized,
        select: ({ windowReplacementConfigurations }) =>
            windowReplacementConfigurations,
    });

    useQueryErrorHandling(query, "Kunde inte hämta ersättningsfönster.");

    return query;
};
