import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { municipalityMethods } from "../api/apiMethods";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "./queryKeys";

export const useMunicipalityRestrictionsQuery = () => {
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const fetchAuthorized =
        authUserData && authUserData.municipalityRole === "ADMIN";

    const query = useQuery({
        queryKey: queryKeys.municipality.restrictions(municipalityId),
        queryFn: () => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                return municipalityMethods(
                    municipalityId
                ).getMunicipalityRestrictions();
            } catch (error) {
                throw new Error("Could not get municipality restrictions");
            }
        },
        enabled: fetchAuthorized,
        select: ({ homeTimeWindowRestrictions }) => homeTimeWindowRestrictions,
    });

    useQueryErrorHandling(query, "Kunde inte hämta tidsfönster.");

    return query;
};
