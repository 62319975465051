import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { municipalityMethods } from "../api/apiMethods";
import { translatePaymentCode } from "../common/helpers/convertPaymentMethod";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "./queryKeys";

export const useAvailablePaymentMethodsForMunicipalityQuery = () => {
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const fetchUnauthoried =
        !authUserData ||
        (authUserData &&
            authUserData.municipalityRole === "ORDERER" &&
            !authUserData.customerCreationAllowed);

    const query = useQuery({
        queryKey:
            queryKeys.municipality.availablePaymentMethods(municipalityId),
        queryFn: () => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                return municipalityMethods(
                    municipalityId
                ).getAvailablePaymentMethods();
            } catch (error) {
                throw new Error("Could not get available payment methods");
            }
        },
        enabled: !!municipalityId && !fetchUnauthoried,
        select: ({ paymentModes }) =>
            paymentModes.map((payment) => ({
                id: payment.code,
                name: translatePaymentCode(payment.code),
            })),
    });

    useQueryErrorHandling(query, "Kunde inte hämta betalmetoder.");

    return query;
};
