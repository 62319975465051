import React, { useContext, useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { genericErrorConfig } from "../../common/exceptions";
import { ReplacementWindowGroup } from "../../common/types";
import { space } from "../../common/variables";
import { Alert } from "../../components/Alert";
import Button from "../../components/Button";
import Cell, { CellHeading } from "../../components/Grid/Cell";
import CellBlock, { CellBlockFooter } from "../../components/Grid/CellBlock";
import Grid from "../../components/Grid/Grid";
import { ModalContext } from "../../components/Modal";
import {
    ReplacedTimeWindowLabel,
    ReplacementTimeWindow,
    ReplacementTimeWindowLabel,
} from "../../components/ReplacementTimeWindow";
import TriggerFormInitially from "../../components/TriggerFormInitially";
import { AuthenticatedUserContext } from "../../contexts/AuthenticatedUserContext";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { useCustomerFormMutation } from "../../mutations/customer/useCustomerFormMutation";
import { useCustomerMatkontoDelete } from "../../mutations/customer/useCustomerMatkontoDelete";
import { useCustomerMatkontoInit } from "../../mutations/customer/useCustomerMatkontoInit";
import { useDeleteCustomerMutation } from "../../mutations/customer/useDeleteCustomerMutation";
import { useAllUnitsQuery } from "../../queries/units/useAllUnitsQuery";
import { useUnitRestrictionsQuery } from "../../queries/units/useUnitRestrictionsQuery";
import { useAvailablePaymentMethodsForMunicipalityQuery } from "../../queries/useAvailablePaymentMethodsForMunicipalityQuery";
import { filterCurrentUserAuthorizedUnits } from "../OrdererView/OrdererView.utils";
import CustomerDeliveryForm from "./CustomerDeliveryForm";
import CustomerDeliveryListing from "./CustomerDeliveryListing";
import CustomerInfoForm from "./CustomerInfoForm";
import CustomerInfoListing from "./CustomerInfoListing";
import { useCustomerDeletionConfirmModal } from "./CustomerSettingsView.hooks";
import {
    CustomerFormData,
    mapCustomerToDefaultFormValues,
    useEditCustomerForm,
} from "./CustomerView.hooks";
import {
    HandlaProxyValidation,
    getModalCustomerErrorText,
} from "./customerDetailsConfig";
import { Customer } from "./customerTypes";

interface Props {
    lastSavedDetails: Customer;
    replacements: ReplacementWindowGroup[] | undefined;
    getCustomerDetails: () => void;
    handlaProxyValidation: HandlaProxyValidation;
}

/**
 * A view for EXISTING customers - so editing of a user, for creating see NewCustomerSettingsView
 */
const CustomerSettingsView = (props: Props) => {
    const { lastSavedDetails, replacements, handlaProxyValidation } = props;
    const navigate = useNavigate();
    const { authUserData } = useContext(AuthenticatedUserContext);
    const { setModalOptions } = useContext(ModalContext);
    const municipalityId = useSelectedMunicipalityId();

    const form = useEditCustomerForm(lastSavedDetails);
    const {
        formState: { isValid, isDirty },
    } = form;

    const watchUnit = useWatch({
        control: form.control,
        name: "unit",
    });

    const { data: availablePaymentMethods } =
        useAvailablePaymentMethodsForMunicipalityQuery();

    const { data: availableTimeFrames } = useUnitRestrictionsQuery(
        watchUnit?.id
    );

    const { data: allUnits } = useAllUnitsQuery();
    const availableUnits = useMemo(
        () =>
            allUnits &&
            authUserData &&
            filterCurrentUserAuthorizedUnits(
                authUserData,
                allUnits,
                municipalityId
            ),
        [allUnits, authUserData, municipalityId]
    );

    const [isEditing, setIsEditing] = useState(false);

    const canUserEditOrCreate =
        authUserData?.municipalityRole === "ADMIN" ||
        (authUserData?.municipalityRole === "ORDERER" &&
            authUserData.customerCreationAllowed);

    const { reset: resetForm } = form;
    useEffect(() => {
        resetForm(mapCustomerToDefaultFormValues(lastSavedDetails));
    }, [resetForm, lastSavedDetails]);

    let customerId = lastSavedDetails.id;

    const deleteCustomerMutation = useDeleteCustomerMutation({
        onSuccess: () => {
            setModalOptions({ visible: false });
            navigate(-1);
        },
        onError: ({ modalErrorString }) => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde inte ta bort slutkund.",
                    modalErrorString
                )
            );
        },
    });

    const customerFormMutation = useCustomerFormMutation({
        onSuccess: () => {
            setIsEditing(false);
        },
        onGenericError: ({ message }) => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    message
                )
            );
        },
        onFieldValidationError: ({ message, jsonError, fieldErrors }) => {
            if (fieldErrors?.length) {
                fieldErrors.forEach((err) => {
                    form.setError(err.fieldName as any, {
                        message: err.message,
                    });
                });
                return;
            }
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    message,
                    getModalCustomerErrorText(jsonError)
                )
            );
        },
    });

    const saveCustomerDetails = (data: CustomerFormData) => {
        customerFormMutation.mutate({
            formData: data,
            billingId: lastSavedDetails.billing?.billingId,
            oldUnitId: lastSavedDetails.unit?.id,
            customerId,
        });
    };

    const showCustomerDeletionConfirmModal = useCustomerDeletionConfirmModal();

    const toggleEditMode = (status: boolean) => {
        if (!status) {
            resetForm();
            setIsEditing(false);
            return;
        }

        setIsEditing(true);
    };

    const matkontoConfirmDeleteMutation = useCustomerMatkontoDelete({
        onSuccess: () => {
            setModalOptions({ visible: false });
        },
        onError: () => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde inte ta bort kort"
                )
            );
        },
    });

    const onMatkontoDelete = (tokenId: string) => {
        setModalOptions({
            visible: true,
            title: `Ta bort kort?`,
            subtitle: `Är du säker på att du vill ta bort kortet?`,
            buttons: [
                <Button
                    size="small"
                    title="Ta bort"
                    theme="red"
                    onClick={() => {
                        const formData = form.getValues();
                        if (!formData.unit?.id) {
                            return;
                        }

                        matkontoConfirmDeleteMutation.mutate({
                            unitId: formData.unit.id,
                            customerId,
                            tokenId,
                        });
                    }}
                />,
                <Button
                    size="small"
                    title="Avbryt"
                    theme="inverted"
                    onClick={() => setModalOptions({ visible: false })}
                />,
            ],
        });
    };

    const matkontoInitMutation = useCustomerMatkontoInit({
        onSuccess: (response) => {
            window.location.href = response.url;
        },
        onError: () => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde inte init Matkonto."
                )
            );
        },
    });

    const onMatkontoInitialize = async () => {
        if (!isValid) {
            return;
        }

        const formData = form.getValues();

        if (
            !formData.unit ||
            !formData.paymentInfoId ||
            formData.paymentInfoId !== "matkonto"
        ) {
            return;
        }

        matkontoInitMutation.mutate({
            unitId: formData.unit.id,
            customerId: customerId,
        });
    };

    const isMutationsPending =
        customerFormMutation.isPending ||
        matkontoInitMutation.isPending ||
        matkontoConfirmDeleteMutation.isPending;

    return (
        <>
            {replacements && replacements.length > 0 && (
                <Alert
                    type="danger"
                    bleedHorizontal="xLarge"
                    bleedTop="xLarge"
                    style={{ marginBottom: "20px" }}>
                    Den här slutkunden har kommande ersättningsfönster
                </Alert>
            )}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                {canUserEditOrCreate &&
                    (!isEditing ? (
                        <Button
                            onClick={() => {
                                toggleEditMode(true);
                            }}
                            title="Ändra"
                            size="small"
                            theme="inverted"
                        />
                    ) : (
                        <Button
                            type="button"
                            size="small"
                            theme="inverted"
                            title="Avbryt"
                            onClick={() => {
                                toggleEditMode(false);
                            }}
                        />
                    ))}
            </div>

            <form onSubmit={form.handleSubmit(saveCustomerDetails)}>
                <Grid gutter={space.padding}>
                    <Cell
                        gutter={space.padding}
                        extraPaddingRight={space.xLarge}>
                        <CellHeading>Personuppgifter</CellHeading>
                        <CellBlock
                            active={isEditing}
                            noOutlines={isEditing}
                            style={{ marginBottom: "20px" }}>
                            {isEditing ? (
                                <>
                                    <CustomerInfoForm
                                        form={form}
                                        enableFormDevTool={false}
                                    />
                                    <CellBlockFooter
                                        marginTop
                                        hideInMobile
                                        disableSubmit={isMutationsPending}
                                        isLoading={isMutationsPending}
                                        submitButtonType="submit"
                                        hasChangesText={
                                            isDirty
                                                ? "Förmuläret har osparade ändringar."
                                                : undefined
                                        }
                                        onCancel={() => toggleEditMode(false)}
                                    />
                                    <TriggerFormInitially
                                        trigger={form.trigger}
                                    />
                                </>
                            ) : (
                                <CustomerInfoListing
                                    {...lastSavedDetails}
                                    handlaProxyValidation={
                                        handlaProxyValidation
                                    }
                                />
                            )}
                        </CellBlock>
                        {replacements && replacements.length > 0 && (
                            <>
                                <CellHeading>
                                    {`Ersättningsfönster ${
                                        replacements.length > 0
                                            ? `(${replacements.length})`
                                            : ""
                                    }`}
                                </CellHeading>
                                {replacements.map((item) => (
                                    <ReplacementTimeWindow spaceBottom>
                                        <ReplacedTimeWindowLabel>{`${
                                            item.replacedHomeTimeWindow.timeSlotDay.split(
                                                " "
                                            )[0]
                                        } ${
                                            item.replacedHomeTimeWindow
                                                .timeSlotDayShort
                                        } ${
                                            item.replacedHomeTimeWindow
                                                .timeSlotTime
                                        }`}</ReplacedTimeWindowLabel>
                                        <ReplacementTimeWindowLabel>{`Ny tid: ${
                                            item.replacementHomeTimeWindow.timeSlotDay.split(
                                                " "
                                            )[0]
                                        } ${
                                            item.replacementHomeTimeWindow
                                                .timeSlotDayShort
                                        } ${
                                            item.replacementHomeTimeWindow
                                                .timeSlotTime
                                        }`}</ReplacementTimeWindowLabel>
                                    </ReplacementTimeWindow>
                                ))}
                            </>
                        )}
                    </Cell>
                    <Cell
                        gutter={space.padding}
                        extraPaddingLeft={space.xLarge}>
                        <CellHeading>
                            Betalning & Leveransinformation
                        </CellHeading>
                        <CellBlock active={isEditing} noOutlines={isEditing}>
                            {isEditing ? (
                                <>
                                    <CustomerDeliveryForm
                                        availablePaymentMethods={
                                            availablePaymentMethods
                                        }
                                        availableTimeFrames={
                                            availableTimeFrames
                                        }
                                        availableUnits={availableUnits}
                                        isNewForm={false}
                                        lastSavedDetails={lastSavedDetails}
                                        onMatkontoInitialize={
                                            onMatkontoInitialize
                                        }
                                        onMatkontoDelete={onMatkontoDelete}
                                        enableFormDevTool
                                        form={form}
                                    />
                                    <CellBlockFooter
                                        hideInDesktop
                                        marginTop
                                        disableSubmit={isMutationsPending}
                                        isLoading={isMutationsPending}
                                        submitButtonType="submit"
                                        hasChangesText={
                                            isDirty
                                                ? "Förmuläret har osparade ändringar."
                                                : undefined
                                        }
                                        onCancel={() => toggleEditMode(false)}
                                    />

                                    <TriggerFormInitially
                                        trigger={form.trigger}
                                    />
                                </>
                            ) : (
                                <CustomerDeliveryListing
                                    {...lastSavedDetails}
                                    handlaProxyValidation={
                                        handlaProxyValidation
                                    }
                                />
                            )}
                        </CellBlock>
                    </Cell>
                </Grid>
            </form>
            {canUserEditOrCreate && (
                <Button
                    theme="redInverted"
                    size="small"
                    title="Ta bort slutkund"
                    onClick={() =>
                        showCustomerDeletionConfirmModal(
                            lastSavedDetails.firstName,
                            lastSavedDetails.lastName,
                            () =>
                                deleteCustomerMutation.mutate({
                                    unitId: lastSavedDetails.unit?.id,
                                    customerId: customerId,
                                })
                        )
                    }
                    wrapperStyle={{
                        overflow: "hidden",
                        marginLeft: "auto",
                        marginTop: space.padding,
                    }}
                />
            )}
        </>
    );
};

export default CustomerSettingsView;
