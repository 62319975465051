import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { municipalityMethods } from "../api/apiMethods";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { Logger } from "../utils/logger/Logger";
import { queryKeys } from "./queryKeys";

export const useMunicipalityDetailsQuery = () => {
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.municipality.details(municipalityId),
        queryFn: () => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                return municipalityMethods(municipalityId).getMunicipality();
            } catch (error: any) {
                Logger.error(error);
                throw new Error(error.message);
            }
        },
        enabled: !!authUserData,
        staleTime: Infinity,
    });

    useQueryErrorHandling(query, "Kunde inte hämta kommun.");

    return query;
};
