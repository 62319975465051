import { useCallback, useEffect, useRef, useState } from "react";

export const useLocalStorage = <T>(
    key: string,
    deserialize: (value: string | null) => T,
    serialize: (value: T) => string | null
) => {
    const [value, setValue] = useState<T>(
        deserialize(localStorage.getItem(key))
    );

    const serializeRef = useRef(serialize);
    const deserializeRef = useRef(deserialize);

    const set = useCallback(
        (value: T) => {
            const serializedValue = serializeRef.current(value);
            if (serializedValue !== null) {
                localStorage.setItem(key, serializedValue);
            } else {
                localStorage.removeItem(key);
            }
            setValue(value);
        },
        [key]
    );

    useEffect(() => {
        setValue(deserializeRef.current(localStorage.getItem(key)));
    }, [key]);

    return [value, set] as const;
};
