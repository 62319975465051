import { useMutation, useQueryClient } from "@tanstack/react-query";
import { unitMethods } from "../api/apiMethods";
import { TimeRestriction } from "../common/types";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queries/queryKeys";
import { UnitInfoFormData } from "../views/UnitView/UnitView.hooks";
import { convertUnitForInput } from "../views/UnitView/unitDetailsConfig";

export const useCreateUnitMutation = (options: {
    onSuccess: (data: string) => void;
    onError: (error: Error) => void;
}) => {
    const municipalityId = useSelectedMunicipalityId();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async (params: {
            input: UnitInfoFormData;
            selectedTimeFrames: TimeRestriction[];
        }) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                const unitId = (
                    await unitMethods(municipalityId).createUnit(
                        convertUnitForInput(params.input)
                    )
                ).municipalityUnitID;

                if (!unitId) {
                    throw new Error(
                        "Could not create a unit. No unit id was returned"
                    );
                }

                await unitMethods(municipalityId).updateUnitRestrictions(
                    params.selectedTimeFrames,
                    unitId
                );

                return unitId;
            } catch (err) {
                throw err;
            }
        },
        onSuccess: (data) => {
            options.onSuccess(data);
            queryClient.invalidateQueries({
                queryKey: queryKeys.unit.all(municipalityId),
            });
        },
        onError: options.onError,
    });

    return mutation;
};
