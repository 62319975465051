import { createGlobalStyle } from "styled-components";
import colors from "./common/colors";
import { fontSize } from "./common/variables";

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    #root {
        min-height: 100%;
    }

    html {
        width: 100%;
        height: 100%;
    }

    body {
        width: 100%;
        height: 100%;
        margin: 0;
        font-family: "Akkurat", sans-serif;
        font-weight: 400;
        color: ${colors.black};
        overflow-x: hidden;
        /* -webkit-font-smoothing: antialiased; */
        -moz-osx-font-smoothing: grayscale;
        /* text-rendering: optimizeLegibility; */
        background-color: ${colors.grayLighter};
    }

    a {
        display: block;
        text-decoration: none;
        color: ${colors.black};
    }

    button {
        appearance: none;
        font-family: "Akkurat", sans-serif;
        background-color: transparent;
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }

    input, textarea {
        font-family: "Akkurat", sans-serif;
    }

    p {
        line-height: ${fontSize.medium};
    }

    img {
        max-width: 100%;
    }
`;
