import React from "react";
import MunicipalityCombobox from "./MunicipalityCombobox";

const MunicipalitySelector = (
    props: Omit<
        React.ComponentPropsWithoutRef<typeof MunicipalityCombobox>,
        "defaultValue"
    > & { municipalityId?: string }
) => {
    const defaultValue = props.municipalities.find(
        (m) => m.code === props.municipalityId
    );

    return (
        <MunicipalityCombobox
            defaultValue={defaultValue}
            municipalities={props.municipalities}
            onSelect={props.onSelect}
        />
    );
};

export default MunicipalitySelector;
