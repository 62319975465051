import { OrderStatus } from "../translations";

/**
 * Converts Order status into Swedish resemblance.
 * @param status Order status.
 */
export const convertOrderStatus = (status: string): OrderStatus => {
    switch (status) {
        case "CANCELLED":
        case "CANCELLING":
        case "SCRAP":
        case "FAILED_INVOICE_RESERVATION":
            return "Makulerad";
        case "SHIPPED_PAID":
        case "SHIPPED_NOT_PAID":
        case "WEBPAYMENT_FAILED_INVOICE_PAYMENT":
            return "På väg";
        case "NEW_INTERNET":
        case "WEBPAYMENT_FAILED_ONLINE_PAYMENT":
        case "WEBPAYMENT_FAILED_ONLINE_AUTHENTICATION":
        case "ROUTING":
        case "ROUTED":
            return "Beställd";
        case "AT_SPEDITOR":
        case "AT_PICKNCOLLECT":
        case "WAITING_FOR_REARRANGE_ROUTE":
        case "READY_TO_PACK":
        case "MANUALLY_CHECK_PACK":
        case "PICKING":
        case "SHIPPING":
        case "READY_TO_PICK":
        case "PICKING_AND_PACKING":
        case "PACKED":
            return "Packas";
        // we don't have a status from Enova to differenciate the successful and failed delivery
        case "HISTORICAL": // used only for statistics by Enova, it is not used on new orders
        case "MIGRATED":
        case "SHIPPED":
            return "Levererad";
        default:
            return "Okänt";
    }
};

export const isOrderCancellable = (status: string): boolean => {
    return status === "NEW_INTERNET";
};
