import { useQuery } from "@tanstack/react-query";
import { unitMethods } from "../../api/apiMethods";
import { useQueryErrorHandling } from "../../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { queryKeys } from "../queryKeys";

export const useUnitByIdQuery = (unitId?: string) => {
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.unit.byId(municipalityId, unitId),
        queryFn: async () => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            if (!unitId) {
                throw new Error("Missing unit id");
            }

            try {
                return unitMethods(municipalityId).getUnit(unitId);
            } catch (error) {
                throw new Error("Could not get unit listing data by id");
            }
        },
        enabled: !!unitId,
    });

    useQueryErrorHandling(query, "Kunde inte hämta uppgifter för denna enhet.");

    return query;
};
