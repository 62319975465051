import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { useCallback, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { customerMethods } from "../../api/apiMethods";
import { CustomerOrderDTO } from "../../api/apiTypes";
import {
    convertOrderStatus,
    isOrderCancellable,
} from "../../common/helpers/convertOrderStatus";
import { toFriendlyDateString } from "../../common/helpers/dateHelpers";
import Button from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import { ModalContext } from "../../components/Modal";
import Plate from "../../components/Plate";
import Table from "../../components/Table/Table";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";

const columnHelper = createColumnHelper<CustomerOrderDTO>();

const CustomerOrdersView = ({
    customerId,
    orders,
}: {
    customerId?: string;
    orders?: CustomerOrderDTO[];
}) => {
    const { setModalOptions } = useContext(ModalContext);
    const municipalityId = useSelectedMunicipalityId();
    const location = useLocation();

    const handleCancelOrder = useCallback(
        async (orderId: string) => {
            if (!customerId) return;
            setModalOptions({ visible: false });

            try {
                const response = await customerMethods(
                    municipalityId
                ).cancelOrder(customerId, orderId);
                if (response.cancelOk) {
                    setModalOptions({
                        visible: true,
                        title: "Ordern har makulerats",
                        subtitle:
                            "Det kan dröja någon minut innan du ser ändringarna på sidan.",
                        buttons: [
                            <Button
                                size="small"
                                title="Jag förstår"
                                onClick={() =>
                                    setModalOptions({ visible: false })
                                }
                            />,
                        ],
                    });
                } else {
                    setModalOptions({
                        visible: true,
                        title: "Kunde inte makulera order",
                        subtitle:
                            "Misslyckades att makulera order. Det kan bero på att ordern nyss har makulerats eller ändrat status.",
                        buttons: [
                            <Button
                                size="small"
                                title="Jag förstår"
                                onClick={() =>
                                    setModalOptions({ visible: false })
                                }
                            />,
                        ],
                    });
                }
            } catch (error: any) {
                setModalOptions({
                    visible: true,
                    title: "Någonting gick snett.",
                    subtitle:
                        "Vänligen ladda om sidan eller försök igen senare.",
                    buttons: [
                        <Button
                            size="small"
                            title="Jag förstår"
                            onClick={() => setModalOptions({ visible: false })}
                        />,
                    ],
                });
            }
        },
        [customerId, setModalOptions, municipalityId, location]
    );

    const onCancelOrder = useCallback(
        (orderId: string) => {
            setModalOptions({
                visible: true,
                title: "Makulera order",
                subtitle: "Är du säker på att du vill makulera ordern?",
                buttons: [
                    <Button
                        size="small"
                        title="Makulera"
                        theme="red"
                        onClick={() => handleCancelOrder(orderId)}
                    />,
                    <Button
                        size="small"
                        title="Ångra"
                        theme="inverted"
                        onClick={() => setModalOptions({ visible: false })}
                    />,
                ],
            });
        },
        [handleCancelOrder, setModalOptions]
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.placed && new Date(row.placed), {
                id: "placed",
                header: () => "Skapad",
                cell: (props) =>
                    toFriendlyDateString(props.row.original.placed),
                meta: {
                    getCellContext: () => {
                        return {
                            length: 5,
                        };
                    },
                },
            }),
            columnHelper.accessor(
                (row) =>
                    row.actualDeliveryDate && new Date(row.actualDeliveryDate),
                {
                    id: "actualDeliveryDate",
                    header: () => "Leveransdatum",
                    cell: (props) =>
                        toFriendlyDateString(
                            props.row.original.actualDeliveryDate
                        ),
                    meta: {
                        getCellContext: () => {
                            return {
                                length: 5,
                            };
                        },
                    },
                }
            ),
            columnHelper.accessor((row) => convertOrderStatus(row.status), {
                id: "status",
                header: () => "Status",
                cell: (props) => props.getValue(),
                meta: {
                    getCellContext: () => {
                        return {
                            length: 5,
                        };
                    },
                },
            }),
            columnHelper.accessor((row) => row.code, {
                id: "code",
                header: () => "Ordernummer",
                cell: (props) => props.getValue(),
                meta: {
                    getCellContext: () => {
                        return {
                            length: 5,
                        };
                    },
                },
            }),
            columnHelper.accessor((row) => isOrderCancellable(row.status), {
                id: "actions",
                header: () => "",
                cell: (props) =>
                    isOrderCancellable(props.row.original.status) && (
                        <Button
                            size="small"
                            theme="inverted"
                            preventDefault
                            onClick={() =>
                                onCancelOrder?.(props.row.original.code)
                            }
                            title="Makulera"
                        />
                    ),
                meta: {
                    getCellContext: () => {
                        return {
                            length: 5,
                        };
                    },
                },
            }),
        ],
        [onCancelOrder]
    );

    const table = useReactTable({
        data: orders || [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    if (!orders?.length) {
        return <EmptyState text="Det finns inga beställningar." />;
    }

    return (
        <Plate noShadow overScroll padding="medium" paddingTop="none">
            <Table table={table} modifiedTheme rowGap="xSmall" />
        </Plate>
    );
};

export default CustomerOrdersView;
