import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../auth/authProvider";
import assets from "../common/assets";
import colors from "../common/colors";
import extractValue from "../common/helpers/extractValue";
import mixins from "../common/mixins";
import { fontSize, space } from "../common/variables";
import AnchorLink from "../components/AnchorLink";
import Button from "../components/Button";
import Container from "../components/Container";
import Loader from "../components/Loader";
import Main from "../components/Main";
import Plate from "../components/Plate";
import { useIsAuthenticated } from "../hooks/useIsAuthenticated";

const AuthSignInView = () => {
    const { signinRedirect } = useContext(AuthContext);
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated === undefined) {
        return <Loader fullHeight />;
    }

    if (isAuthenticated === true) {
        return <Navigate to={"/"} />;
    }

    return (
        <Main centerContent backgroundImage={assets.background}>
            <Container size="small">
                <Plate padding="xLarge" spaceBottom spaceTop>
                    <Content>
                        <Title>Välkommen till Ombudshandel Online</Title>
                        <Subtitle>
                            Här hanteras beställningar av organisationer för
                            enheter och slutkunder. Du har även möjlighet att
                            skapa nya beställare, användare och administrera
                            information.
                        </Subtitle>
                        <Image src={assets.screen} />
                        <Button
                            title="Fortsätt för att logga in"
                            wrapperStyle={{ width: "100%" }}
                            containerStyle={{ width: "100%" }}
                            onClick={() => signinRedirect()}
                        />
                        <Footnote>
                            <span style={{ marginRight: space.xxSmall }}>
                                Har du kommit fel?
                            </span>
                            <AnchorLink href="https://coop.se">
                                Gå vidare till Coop.se
                            </AnchorLink>
                        </Footnote>
                    </Content>
                </Plate>
            </Container>
        </Main>
    );
};

export default AuthSignInView;

const Content = styled.div`
    text-align: center;
`;

const Title = styled.h1`
    font-size: ${fontSize.xLarge};
    margin-top: 0;
    margin-bottom: ${space.medium};
`;

const Subtitle = styled.p`
    font-size: ${fontSize.medium};
    margin-top: 0;
    margin-bottom: ${extractValue(space.xLarge) * 2}px;
    color: ${colors.grayDarker};
    line-height: ${fontSize.xxLarge};
`;

const Image = styled.img`
    max-width: 80%;
    height: auto;
    border-radius: ${mixins.borderRadius.default};
    box-shadow: ${mixins.boxShadow.bottomAngle};
    overflow: hidden;
    margin-bottom: ${extractValue(space.xLarge) * 2}px;
`;

const Footnote = styled.p`
    display: flex;
    font-size: ${fontSize.small};
    color: ${colors.grayDarker};
    margin-top: ${space.medium};
    justify-content: center;
`;
