import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";

interface Props {
    centerContent?: boolean;
    backgroundImage?: string;
    children: React.ReactNode;
}

const Main = (props: PropsWithChildren<Props>) => (
    <Wrapper
        background={props.backgroundImage}
        centerContent={props.centerContent}>
        {props.children}
    </Wrapper>
);

export default Main;

const Wrapper = styled.div<{ centerContent?: boolean; background?: string }>`
    width: 100%;
    height: 100%;
    min-height: ${({ centerContent }) => centerContent && "100vh"};
    overflow: hidden;

    ${({ background }) =>
        background?.length &&
        css`
            background-image: url(${background});
            background-size: cover;
            background-position: center;
        `};

    ${({ centerContent }) =>
        centerContent &&
        css`
            display: flex;
            align-items: center;
        `}
`;
