import isEqual from "lodash/isEqual";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import assets from "../../common/assets";
import { genericErrorConfig } from "../../common/exceptions";
import { ListOption } from "../../common/types";
import { isHybrisValidationError } from "../../common/validations";
import { space } from "../../common/variables";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Cell, { CellHeader, CellHeading } from "../../components/Grid/Cell";
import CellBlock, {
    CellBlockFooter,
    CellBlockSection,
    CellBlockTitle,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import Grid from "../../components/Grid/Grid";
import Loader from "../../components/Loader";
import { ModalContext } from "../../components/Modal";
import OptionsList from "../../components/OptionsList";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import Tag from "../../components/Tag";
import TriggerFormInitially from "../../components/TriggerFormInitially";
import { AuthenticatedUserContext } from "../../contexts/AuthenticatedUserContext";
import useUnsavedChangesModal from "../../hooks/useUnsavedChangesModal";
import { useDeleteOrdererMutation } from "../../mutations/useDeleteOrdererMutation";
import { useUpdateOrdererMutation } from "../../mutations/useUpdateOrdererMutation";
import { useAllUnitsQuery } from "../../queries/units/useAllUnitsQuery";
import { useOrdererByIdQuery } from "../../queries/useOrdererByIdQuery";
import {
    extractFieldErrorsFromHybrisErrorAsArray,
    getModalCustomerErrorText,
} from "../CustomerView/customerDetailsConfig";
import { useOrdererInfoForm } from "./OrdererDetailsView.hooks";
import OrdererInfoForm from "./OrdererInfoForm";
import OrdererInfoListing from "./OrdererInfoListing";
import { filterOrdererAuthorizedUnits, sortUnits } from "./OrdererView.utils";
import {
    convertOrdererForInput,
    convertOrdererFromOutput,
    mapHybrisOrdererErrorField,
} from "./ordererDetailsConfig";
import { OrdererInput } from "./ordererTypes";

interface OrdererFormsEditing {
    information?: boolean;
    units?: boolean;
}

const EditOrdererView = () => {
    const navigate = useNavigate();

    const { authUserData } = useContext(AuthenticatedUserContext);
    const { setModalOptions } = useContext(ModalContext);
    const { ordererId } = useParams<{ ordererId: string }>();

    const [isEditing, setIsEditing] = useState<OrdererFormsEditing | undefined>(
        {
            information: false,
            units: false,
        }
    );

    const ordererQuery = useOrdererByIdQuery(ordererId, {
        enabled: true,
    });

    const allUnitsQuery = useAllUnitsQuery();
    const allUnitsOptions: ListOption[] = useMemo(
        () =>
            allUnitsQuery.data?.map((item) => ({
                id: item.code,
                name: item.name,
            })) || [],
        [allUnitsQuery.data]
    );

    const ordererAuthorizedUnits = useMemo(
        () =>
            (ordererQuery.data &&
                allUnitsQuery.data &&
                filterOrdererAuthorizedUnits(
                    ordererQuery.data,
                    allUnitsQuery.data
                )) ||
            [],
        [allUnitsQuery.data, ordererQuery.data]
    );

    console.log(ordererAuthorizedUnits);

    const [selectedUnits, setSelectedUnits] = useState<ListOption[]>(
        ordererAuthorizedUnits
    );
    // Sync unit data into a state when API call returns with orderer's units
    useEffect(() => {
        if (ordererAuthorizedUnits) {
            setSelectedUnits(ordererAuthorizedUnits);
        }
    }, [ordererAuthorizedUnits]);

    const updateUnitList = (selectedUnit: ListOption) => {
        const alreadySelected = selectedUnits.find(
            (unit) => unit.id === selectedUnit.id
        );

        if (alreadySelected) {
            const updatedUnits = [...selectedUnits].filter(
                (unit) => unit.id !== selectedUnit.id
            );
            setSelectedUnits(updatedUnits);
        } else {
            setSelectedUnits([...selectedUnits, selectedUnit]);
        }
    };

    const resetSelectedUnits = () => {
        setSelectedUnits(ordererAuthorizedUnits);
    };

    const deleteOrdererMutation = useDeleteOrdererMutation({
        onSuccess: () => {
            setModalOptions({ visible: false });
            navigate(-1);
        },
    });

    const updateOrdererMutation = useUpdateOrdererMutation({
        onSuccess: () => {
            setIsEditing(undefined);
        },
        onError: (error) => {
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde ej skapa beställare.",
                    getModalCustomerErrorText(error)
                )
            );

            if (isHybrisValidationError(error)) {
                const fieldErrors = extractFieldErrorsFromHybrisErrorAsArray(
                    error,
                    mapHybrisOrdererErrorField
                );

                fieldErrors.forEach((element) => {
                    form.setError(element.fieldName as any, {
                        message: element.message,
                        type: "required",
                    });
                });
            }
        },
    });

    const updateOrderer = (input: OrdererInput) => {
        updateOrdererMutation.mutate({
            ordererId: ordererId,
            input: input,
        });
    };

    const showDeletionConfirmModal = () => {
        setModalOptions({
            visible: true,
            title: `Ta bort beställare?`,
            subtitle: `Är du säker på att du vill ta bort ${ordererQuery.data?.firstName} ${ordererQuery.data?.lastName}?`,
            buttons: [
                <Button
                    size="small"
                    title="Ta bort"
                    theme="red"
                    onClick={() => deleteOrdererMutation.mutate({ ordererId })}
                />,
                <Button
                    size="small"
                    title="Avbryt"
                    theme="inverted"
                    onClick={() => setModalOptions({ visible: false })}
                />,
            ],
        });
    };

    const form = useOrdererInfoForm();

    // Sync orderer data into a form when API call returns
    useEffect(() => {
        if (ordererQuery.isSuccess) {
            form.reset(convertOrdererFromOutput(ordererQuery.data));
        }
    }, [form, ordererQuery.data, ordererQuery.isSuccess]);

    const resetForm = () => {
        form.reset(convertOrdererFromOutput(ordererQuery.data));
    };

    const selectedUnitsHasChanged = !isEqual(
        sortUnits(selectedUnits),
        sortUnits(ordererAuthorizedUnits)
    );

    console.log(selectedUnitsHasChanged);

    const showUnsavedChangesModal = useUnsavedChangesModal();

    const toggleEditMode = (directToggleTo?: keyof OrdererFormsEditing) => {
        if (!directToggleTo) {
            resetForm();
            resetSelectedUnits();
            setIsEditing(undefined);
            return;
        }

        if (form.formState.isDirty || selectedUnitsHasChanged) {
            showUnsavedChangesModal(() => {
                resetForm();
                resetSelectedUnits();
                setIsEditing({
                    [directToggleTo]: true,
                });
            });
        } else {
            setIsEditing({
                [directToggleTo]: true,
            });
        }
    };

    const isMutationLoading =
        deleteOrdererMutation.isPending || updateOrdererMutation.isPending;

    return (
        <Container>
            <SubHeader
                backArrow
                title={`${ordererQuery.data?.firstName} ${ordererQuery.data?.lastName}`}
            />
            <Plate padding="large" spaceBottom={true}>
                {ordererQuery.isLoading ? (
                    <Loader />
                ) : (
                    <Grid gutter={space.padding}>
                        <Cell gutter={space.padding}>
                            <CellHeading>Personuppgifter</CellHeading>
                            <CellBlock
                                active={isEditing?.information}
                                noOutlines={false}
                                onEdit={() => toggleEditMode("information")}>
                                {isEditing?.information ? (
                                    <>
                                        <CellBlockSection>
                                            <CellBlockTitle>
                                                Ändra personuppgifter
                                            </CellBlockTitle>
                                        </CellBlockSection>
                                        <CellBlockSection>
                                            <OrdererInfoForm
                                                onCancel={() =>
                                                    toggleEditMode(undefined)
                                                }
                                                {...form}
                                                isSubmitLoading={
                                                    isMutationLoading
                                                }
                                                isNewForm={false}
                                                onSubmit={(data) =>
                                                    updateOrderer(
                                                        convertOrdererForInput(
                                                            data,
                                                            selectedUnits
                                                        )
                                                    )
                                                }
                                            />
                                            <TriggerFormInitially
                                                trigger={form.trigger}
                                            />
                                        </CellBlockSection>
                                    </>
                                ) : (
                                    <OrdererInfoListing
                                        data={form.getValues()}
                                        isValid={form.formState.isValid}
                                    />
                                )}
                            </CellBlock>
                        </Cell>
                        <Cell gutter={space.padding}>
                            <CellHeader>
                                <CellHeading>
                                    Enheter
                                    {selectedUnits?.length > 0 &&
                                        ` (${selectedUnits.length})`}
                                </CellHeading>
                                {isEditing?.units &&
                                    selectedUnits.length > 0 && (
                                        <div>
                                            <Tag>
                                                {selectedUnits.length === 1
                                                    ? `1 vald enhet`
                                                    : `${selectedUnits.length} valda enheter`}
                                            </Tag>
                                        </div>
                                    )}
                            </CellHeader>
                            {allUnitsOptions?.length || isEditing?.units ? (
                                <CellBlock
                                    onEdit={() => toggleEditMode("units")}
                                    active={isEditing?.units}>
                                    {!!allUnitsQuery.data?.length &&
                                    isEditing?.units ? (
                                        <>
                                            <CellBlockSection>
                                                <CellBlockTitle>
                                                    Ändra enheter
                                                </CellBlockTitle>
                                            </CellBlockSection>
                                            <CellBlockSection>
                                                <OptionsList
                                                    overflowValue={space.medium}
                                                    setChoice={updateUnitList}
                                                    data={allUnitsOptions}
                                                    selectedData={selectedUnits}
                                                />
                                            </CellBlockSection>
                                            <CellBlockFooter
                                                isLoading={isMutationLoading}
                                                disableSubmit={
                                                    !selectedUnitsHasChanged
                                                }
                                                hasChangesText={
                                                    selectedUnitsHasChanged
                                                        ? "Valet av enheter har osparade ändringar."
                                                        : undefined
                                                }
                                                onCancel={() =>
                                                    toggleEditMode(undefined)
                                                }
                                                onSubmit={() =>
                                                    updateOrderer(
                                                        convertOrdererForInput(
                                                            convertOrdererFromOutput(
                                                                ordererQuery.data
                                                            ),
                                                            selectedUnits
                                                        )
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        selectedUnits.map((unit) => (
                                            <CellBlockValue key={unit.id}>
                                                {unit.name}
                                            </CellBlockValue>
                                        ))
                                    )}
                                </CellBlock>
                            ) : (
                                <Button
                                    outsideTitle="Lägg till enheter"
                                    shape="round"
                                    onClick={() => toggleEditMode("units")}
                                    icon={assets.plus}
                                />
                            )}
                        </Cell>
                    </Grid>
                )}
                {authUserData?.municipalityRole === "ADMIN" && (
                    <Button
                        theme="inverted"
                        title="Ta bort"
                        onClick={() => showDeletionConfirmModal()}
                        wrapperStyle={{
                            overflow: "hidden",
                            marginLeft: "auto",
                            marginTop: space.padding,
                        }}
                    />
                )}
            </Plate>
        </Container>
    );
};

export default EditOrdererView;
