import styles from "./Combobox.module.scss";
import type { ComboboxSize, ComboboxTheme } from "./Combobox.types";

export const getTheme = (theme: ComboboxTheme) => {
    switch (theme) {
        case "lightGreen":
            return styles["Combobox--lightGreen"];
        case "green":
            return styles["Combobox--green"];
        case "whiteGreen":
            return styles["Combobox--whiteGreen"];
        case "white":
            return styles["Combobox--white"];
        case "whiteGreenDark":
            return styles["Combobox--whiteGreenDark"];
        default:
            return null;
    }
};

export const getSize = (theme: ComboboxSize) => {
    switch (theme) {
        case 40:
            return styles["ComboboxInputContainer--size40"];
        case 48:
            return styles["ComboboxInputContainer--size48"];
        default:
            return null;
    }
};
