import React, { useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";
import { pathBase } from "../auth/authConst";
import { MatkontoTmpInfoProvider } from "../common/helpers/matkontoTmpInfoProider";
import mixins from "../common/mixins";
import Header from "../components/Header";
import Main from "../components/Main";
import { ModalContext } from "../components/Modal";
import Navigation from "../components/Navigation";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { useMunicipalityDetailsQuery } from "../queries/useMunicipalityDetailsQuery";
import { routes } from "../routes";
import AnimatedOutlet from "./AnimatedOutlet";

const Layout = () => {
    const location = useLocation();
    const { authUserData } = useContext(AuthenticatedUserContext);
    const { modalOptions } = useContext(ModalContext);
    const municipalityId = useSelectedMunicipalityId();

    const navigationItems = useMemo(
        () =>
            [
                {
                    title: "Slutkunder",
                    path: routes.customers(municipalityId),
                } as const,
                {
                    title: "Beställare",
                    path: routes.orderers(municipalityId),
                } as const,
                {
                    title: "Enheter",
                    path: routes.units(municipalityId),
                } as const,
            ] as const,
        [municipalityId]
    );

    const transitions = useTransition(location, {
        initial: { opacity: 0, transform: "translate3d(1%, 0, 0)" },
        from: { opacity: 0, transform: "translate3d(1%, 0, 0)" },
        enter: {
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
            animationDelay: "150",
            delay: 150,
        },
        leave: {
            opacity: 0,
            transform: "translate3d(-1%, 0, 0)",
            animationDuration: "145",
            config: { duration: 145 },
        },
    });

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
        });
    }, [location]);

    useMatkontoCookieGuard();

    const { data: municipalityDetails, isSuccess: hasMunicipalityDetails } =
        useMunicipalityDetailsQuery();
    const allowedMunicipalities = authUserData?.authorisedMunicipalities;

    const showMunicipalityName =
        hasMunicipalityDetails &&
        (allowedMunicipalities === undefined ||
            allowedMunicipalities.length < 2);

    const municipalityName = hasMunicipalityDetails
        ? municipalityDetails.name
        : "Ombudshandel";

    return (
        <>
            <Header
                blur={modalOptions.visible}
                position="sticky"
                municipalityName={
                    showMunicipalityName ? municipalityName : undefined
                }>
                <Navigation
                    items={
                        authUserData?.municipalityRole === "ADMIN"
                            ? [...navigationItems]
                            : [navigationItems[0]]
                    }
                    municipalityId={municipalityId}
                    allowedMunicipalities={allowedMunicipalities}
                />
            </Header>
            <Main>
                {transitions((style, item, t) => (
                    <Content
                        style={style}
                        blur={modalOptions.visible ? 1 : 0}
                        key={item.pathname}>
                        <AnimatedOutlet />
                    </Content>
                ))}
            </Main>
        </>
    );
};

export default Layout;

export const Content = styled(animated.div)<{ blur?: number }>`
    position: absolute;
    width: 100%;
    transition: filter ${mixins.transitions.large};
    filter: ${({ blur }) => (blur ? "blur(4px)" : "none")};
`;

const useMatkontoCookieGuard = () => {
    const location = useLocation();

    // the only locations that can have the tmp values are base path (before firing react router), and Matkonto callback url
    const locationIsAllowed = () => {
        return (
            location.pathname.includes(routes.matkontoCallback) ||
            location.pathname === pathBase
        );
    };

    if (MatkontoTmpInfoProvider.exists() && !locationIsAllowed()) {
        MatkontoTmpInfoProvider.remove();
    }
};
