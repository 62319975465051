import type { ComboboxOptionProps } from "@headlessui/react";
import { Combobox as HeadlessUICombobox } from "@headlessui/react";
import classnames from "classnames";
import type { ElementType } from "react";
import * as React from "react";

import assets from "../../common/assets";
import styles from "./Combobox.module.scss";

const ComboboxOption = <TValue, TTag extends ElementType>(
    props: React.PropsWithChildren<ComboboxOptionProps<TTag, TValue>>
) => {
    return (
        <HeadlessUICombobox.Option
            as="div"
            className={({ active }) =>
                `${styles.Option} ${active ? styles.OptionActive : ""}`
            }
            value={props.value}>
            {({ selected }) => (
                <>
                    {selected && assets.checkmarkTransparent}
                    <div
                        className={classnames(
                            selected
                                ? styles.OptionSelected
                                : styles.OptionUnselected
                        )}>
                        {props.children}
                    </div>
                </>
            )}
        </HeadlessUICombobox.Option>
    );
};

export default ComboboxOption;
