import { useMutation } from "@tanstack/react-query";
import { customerMethods } from "../../api/apiMethods";
import { MatkontoTmpInfoProvider } from "../../common/helpers/matkontoTmpInfoProider";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { Logger } from "../../utils/logger/Logger";

export const useCustomerMatkontoInit = (options: {
    onSuccess?: (data: { url: string }) => void;
    onError: () => void;
}) => {
    const municipalityId = useSelectedMunicipalityId();

    const mutation = useMutation({
        mutationFn: async ({
            unitId,
            customerId,
        }: {
            unitId: string;
            customerId: string;
        }) => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                MatkontoTmpInfoProvider.set({
                    municipalityId: municipalityId,
                    unitId: unitId,
                    customerId: customerId,
                });

                const response = await customerMethods(
                    municipalityId
                ).initializeMatkonto(unitId, customerId);
                return response;
            } catch (err) {
                throw err;
            }
        },
        onSuccess: options.onSuccess,
        onError: async (response: Response) => {
            Logger.error(response);

            let jsonError;
            try {
                jsonError = await response.json();
                Logger.error(jsonError);
            } catch {}

            options.onError();
        },
    });

    return mutation;
};
