import { Combobox as HeadlessUICombobox } from "@headlessui/react";
import classnames from "classnames";
import type { ElementType } from "react";
import * as React from "react";

import assets from "../../common/assets";
import styles from "./Combobox.module.scss";
import type { ComboboxSize, ComboboxTheme } from "./Combobox.types";
import { getSize, getTheme } from "./Combobox.utils";

type ComboboxProps<TValue, TTag extends ElementType = "li"> = Omit<
    React.PropsWithChildren<
        React.ComponentProps<typeof HeadlessUICombobox<TValue, TTag>>
    >,
    "onChange"
> & {
    query: string;
    itemCount: number;
    displayName: (value: TValue) => string;
    onQueryChange: (value: string) => void;
    onValueChange: (value: TValue) => void;
    theme?: ComboboxTheme;
    size?: ComboboxSize;
    placeholder: string;
};

const Combobox = <TValue,>({
    query,
    itemCount,
    onQueryChange,
    onValueChange,
    displayName,
    theme = "green",
    size = 40,
    placeholder,
    ...rest
}: ComboboxProps<TValue>) => {
    const comboboxTheme = getTheme(theme);
    const comboboxSize = getSize(size);

    return (
        <HeadlessUICombobox
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            onChange={(value) => onValueChange(value)}>
            <div className={classnames(styles.Combobox, comboboxTheme)}>
                <div
                    className={classnames(
                        styles.ComboboxInputContainer,
                        comboboxSize
                    )}>
                    <HeadlessUICombobox.Input<TValue>
                        className={styles.ComboboxInput}
                        placeholder={placeholder}
                        displayValue={displayName}
                        onChange={(event) => onQueryChange(event.target.value)}
                    />
                    <HeadlessUICombobox.Button
                        className={styles.ComboboxInputIcon}
                        aria-label="Öppna listan">
                        <div className={styles.ComboboxIconWrapper}>
                            {assets.angle}
                        </div>
                    </HeadlessUICombobox.Button>
                </div>
                <HeadlessUICombobox.Options
                    className={styles.OptionsListContainer}
                    as="div">
                    <div className={styles.OptionsList}>
                        {query && itemCount === 0 && (
                            <li
                                className={classnames(
                                    styles.Option,
                                    styles.OptionNotFound
                                )}>
                                Inget hittat.
                            </li>
                        )}
                        {rest.children}
                    </div>
                </HeadlessUICombobox.Options>
            </div>
        </HeadlessUICombobox>
    );
};

export default Combobox;
