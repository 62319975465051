import React, { useState } from "react";
import styled, { css } from "styled-components";
import assets from "../common/assets";
import colors from "../common/colors";
import mixins from "../common/mixins";
import { ValidationResult } from "../common/validations";
import { fontSize, space } from "../common/variables";

interface Props
    extends React.HTMLProps<HTMLInputElement | HTMLTextAreaElement> {
    placeholder?: string;
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    onBlur?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    value?: string;
    multiline?: boolean;
    maxLength?: number;
    required?: boolean;
    validation?: ValidationResult;
}

const INPUT_HEIGHT = "50px";

type InputValidationState = "valid" | "invalid" | "not-decided";

const TextInput = (props: Props) => {
    const {
        style,
        placeholder,
        onChange,
        value,
        disabled,
        readOnly,
        onBlur,
        multiline,
        maxLength = 100,
        required,
        type,
        validation,
    } = props;
    const [isFocused, setIsFocused] = useState(false);
    const charCount = value?.length;

    function handleFocus() {
        setIsFocused(true);
    }

    function handleChange(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        onChange?.(e);
    }

    function handleBlur(
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        onBlur?.(e);
        setIsFocused(false);
    }

    let validationState: InputValidationState = "not-decided";
    if (validation) {
        if (validation.isValid) {
            validationState = "valid";
        } else {
            validationState = "invalid";
        }
    }

    return (
        <Wrapper
            style={{ height: multiline ? "auto" : INPUT_HEIGHT, ...style }}
            status={validationState}
            readOnly={readOnly}>
            {placeholder && (
                <Placeholder
                    multiline={multiline}
                    retracted={isFocused || !!value?.length}>
                    {`${placeholder}`}
                    {required && ` *`}
                </Placeholder>
            )}
            {multiline && charCount != null && (
                <CharacterCount>{`${charCount}/${maxLength}`}</CharacterCount>
            )}

            {validation && validationState === "invalid" && (
                <Error>{validation.message}</Error>
            )}

            {/* Red round icon  */}
            {validation && validationState === "invalid" && (
                <ValidationIcon
                    valid={false}
                    readOnly={readOnly}
                    multiline={multiline}>
                    {assets.exclamation}
                </ValidationIcon>
            )}

            {/* Green round icon */}
            {validation &&
                !!value /* dont set green mark when there is no value yet */ &&
                validationState === "valid" && (
                    <ValidationIcon
                        valid
                        readOnly={readOnly}
                        multiline={multiline}>
                        {assets.checkmark}
                    </ValidationIcon>
                )}

            {multiline ? (
                <TextArea
                    value={value}
                    rows={6}
                    readOnly={readOnly}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    maxLength={maxLength}
                    onChange={handleChange}
                />
            ) : (
                <Input
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    type={type}
                    onChange={handleChange}
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div<{
    status: "valid" | "invalid" | "not-decided";
    readOnly?: boolean;
}>`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    background-color: ${colors.border};
    border-radius: ${mixins.borderRadius.default};
    border-width: 1px;
    border-style: solid;
    opacity: ${({ readOnly }) => (readOnly ? "0.7" : "1")};

    ${({ status, readOnly }) => {
        if (readOnly) {
            return css`
                border-color: transparent;
            `;
        }

        if (status === "invalid") {
            return css`
                border-color: ${colors.red};
            `;
        } else {
            return css`
                border-color: transparent;

                &:hover {
                    border-color: ${colors.grayMid};
                }
            `;
        }
    }};
`;

const Placeholder = styled.span`
    font-size: ${fontSize.small};
    line-height: 10px;
    position: absolute;
    left: ${space.small};
    color: ${colors.grayDarker};
    pointer-events: none;
    transition: transform ${mixins.transitions.default},
        font-size ${mixins.transitions.default};

    ${({
        retracted,
        multiline,
    }: {
        retracted?: boolean;
        multiline?: boolean;
    }) => {
        if (multiline) {
            if (retracted) {
                return css`
                    top: 0;
                    transform: translateY(10px);
                    font-size: ${fontSize.xSmall};
                `;
            } else {
                return css`
                    top: 0;
                    transform: translateY(20px);
                    font-size: ${fontSize.small};
                `;
            }
        } else {
            if (retracted) {
                return css`
                    top: 50%;
                    transform: translateY(-150%);
                    font-size: ${fontSize.xSmall};
                `;
            } else {
                return css`
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: ${fontSize.small};
                `;
            }
        }
    }}
`;

const Input = styled.input`
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: ${fontSize.small};
    line-height: ${fontSize.xxLarge};
    padding: ${space.large} ${space.xxLarge} 0 ${space.small};
    outline: none;
    cursor: ${({ disabled }: { disabled?: boolean }) =>
        disabled ? "cursor" : "text"};
    height: 48px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type="number"] {
        -moz-appearance: textfield;
    }
    ::-ms-clear {
        display: none;
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: ${fontSize.small};
    line-height: ${fontSize.xxLarge};
    padding: ${space.xLarge} ${space.xxLarge} 0 ${space.small};
    resize: none;
    outline: none;
    cursor: ${({ readOnly }: { readOnly?: boolean }) =>
        readOnly ? "not-allowed" : "text"};

    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const CharacterCount = styled.span`
    font-size: ${fontSize.xSmall};
    position: absolute;
    right: ${space.small};
    bottom: ${space.small};
    color: ${colors.grayDarker};
    pointer-events: none;
`;

const Error = styled.span`
    font-size: ${fontSize.xSmall};
    position: absolute;
    left: 0;
    bottom: -18px;
    color: ${colors.red};
    pointer-events: none;
`;

const ValidationIcon = styled.span<{
    readOnly?: boolean;
    multiline?: boolean;
    valid?: boolean;
}>`
    height: 16px;
    width: 16px;
    position: absolute;
    right: ${space.small};
    pointer-events: none;
    top: ${({ multiline }) => (multiline ? 0 : "50%")};
    transform: ${({ multiline }) =>
        multiline ? `translateY(${space.small})` : "translateY(-50%)"};
    fill: ${({ valid }) => (valid ? colors.green : colors.red)};
`;

export default TextInput;
