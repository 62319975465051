import React, { useContext } from "react";
import assets from "../common/assets";
import Header from "../components/Header";
import Main from "../components/Main";
import { ModalContext } from "../components/Modal";

const MinimalLayout = (props: React.PropsWithChildren<{}>) => {
    const { modalOptions } = useContext(ModalContext);

    return (
        <Main centerContent backgroundImage={assets.background}>
            <Header blur={modalOptions.visible} position="fixed" />
            {props.children}
        </Main>
    );
};

export default MinimalLayout;
