import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ordererMethods } from "../api/apiMethods";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { Orderer } from "../views/OrdererView/ordererTypes";
import { queryKeys } from "./queryKeys";

export const useOrdererByIdQuery = (
    ordererId?: string,
    options?: {
        enabled: boolean;
    }
) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.orderer.byId(municipalityId, ordererId),
        queryFn: () => {
            if (!authUserData || !ordererId) {
                throw new Error("No auth user data");
            }

            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                return ordererMethods(municipalityId).getOrderer(ordererId);
            } catch (error) {
                throw new Error("Could not get orderer data");
            }
        },
        enabled: !!authUserData && !!ordererId && (!options || options.enabled),
        initialData: () =>
            (
                queryClient.getQueryData(
                    queryKeys.orderer.all(municipalityId)
                ) as {
                    userlist: Orderer[];
                }
            )?.userlist?.find((orderer) => orderer.customerId === ordererId),
    });

    useQueryErrorHandling(
        query,
        "Kunde inte hämta uppgifter för denna beställare.",
        () => {
            navigate(-1);
        }
    );

    return query;
};
