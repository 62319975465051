import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MunicipalitySelector from "../../components/MunicipalitySelector";
import { AuthenticatedUserContext } from "../../contexts/AuthenticatedUserContext";
import { routes } from "../../routes";
import MunicipalityBaseView from "./MunicipalityBaseView";

const SelectMunicipalityView = () => {
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalities = authUserData?.authorisedMunicipalities;

    const navigate = useNavigate();

    if (!municipalities) return null;

    return (
        <MunicipalityBaseView
            title="Välj organisation att administrera"
            subTitle="Du kan alltid byta organisation i ett senare steg.">
            <MunicipalitySelector
                municipalities={municipalities}
                onSelect={(municipality) =>
                    navigate(routes.customers(municipality.code))
                }
            />
        </MunicipalityBaseView>
    );
};

export default SelectMunicipalityView;
