import { ListOption } from "../../common/types";
import { AuthContextObject } from "../../contexts/AuthenticatedUserContext";
import { Unit } from "../UnitView/unitTypes";
import { Orderer, OrdererListingItem } from "./ordererTypes";

/**
 * Filters the units that the orderer is authorised to
 * @param orderer  The orderer to filter units for
 * @param allUnits  All units available to the current user
 * @returns  The orderer and the units that the orderer is authorised to
 */
export const filterOrdererAuthorizedUnits = (
    orderer: Orderer,
    allUnits: Unit[]
) => {
    const authUnits = allUnits
        .filter((unit) =>
            orderer.authorisedUnitCodes?.find((code) => code === unit.code)
        )
        .map((authUnit) => ({
            id: authUnit.code,
            name: authUnit.name,
        }));

    return authUnits;
};

/**
 *  Filters the units that given orderers are authorised to
 * @param orderers  The orderers to filter units for
 * @param allUnits  All units available to the current user
 * @returns  The orderers and the units that the orderers are authorised to
 */
export const filterOrderersAuthorizedUnits = (
    orderers: Orderer[],
    allUnits: Unit[]
) =>
    orderers.reduce((array, orderer) => {
        const authUnits = filterOrdererAuthorizedUnits(orderer, allUnits);
        return [
            ...array,
            {
                orderer,
                authUnits,
            },
        ];
    }, [] as OrdererListingItem[]);

/**
 * Filters the units that the current user is authorised to
 * @param authUserData  The current user's auth data
 * @param units All units, nevermind the user's role
 * @returns The units that the current user is authorised to
 */
export const filterCurrentUserAuthorizedUnits = (
    authUserData: AuthContextObject,
    units: Unit[],
    municipalityId: string
) => {
    const authorizedAvailableUnits = units.filter((u) =>
        authUserData!.municipalityRole === "ADMIN"
            ? true
            : authUserData?.municipalityAuthorisedUnitCodesMap[
                  municipalityId
              ].includes(u.code)
    );
    return authorizedAvailableUnits.map((unit) => ({
        id: unit.code,
        name: unit.name,
    }));
};

export const sortUnits = (data: ListOption[]) => {
    return data
        .map((item, i) => ({
            ...item,
            index: i,
        }))
        .sort((a, b) => {
            if (a.id < b.id) {
                return -1;
            }
            if (b.id < a.id) {
                return 1;
            }
            return 0;
        })
        .map((item) => data[item.index]);
};
