import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../auth/authProvider";
import Button from "../../components/Button";
import MunicipalitySelector from "../../components/MunicipalitySelector";
import { AuthenticatedUserContext } from "../../contexts/AuthenticatedUserContext";
import { routes } from "../../routes";
import { telemetry } from "../../utils/telemetry/TelemetryService";
import MunicipalityBaseView from "./MunicipalityBaseView";

const MunicipalityAccessDenied = () => {
    const [searchParams] = useSearchParams();
    const municipalityId = searchParams.get("municipalityId");

    const navigate = useNavigate();
    const { signout } = useContext(AuthContext);
    const { authUserData } = useContext(AuthenticatedUserContext);
    const allowedMunicipalities = authUserData?.authorisedMunicipalities;

    useEffect(() => {
        telemetry.trackInfo("Municipality Access Denied", {
            attemptedMunicipalityId: municipalityId,
            uid: authUserData?.uid,
            identityId: authUserData?.identityId,
        });
    }, [authUserData, municipalityId]);

    if (!allowedMunicipalities) return null;

    const hasMoreThanOneMunicipality = allowedMunicipalities.length > 1;

    return (
        <MunicipalityBaseView
            title="Du har inte tillgång till denna organisation"
            subTitle={
                hasMoreThanOneMunicipality
                    ? "Sök eller välj bland organisationer du kan administrera eller logga ut."
                    : "Gå till den organisation du har behörighet till eller logga ut."
            }>
            {hasMoreThanOneMunicipality ? (
                <>
                    <MunicipalitySelector
                        municipalities={allowedMunicipalities}
                        onSelect={(municipality) =>
                            navigate(`${routes.customers(municipality.code)}`, {
                                replace: true,
                            })
                        }
                    />
                    <Button
                        theme={"greenDark"}
                        wrapperStyle={{ width: "100%" }}
                        containerStyle={{ width: "100%" }}
                        onClick={signout}
                        title="Logga ut"
                    />
                </>
            ) : (
                <>
                    <Button
                        theme={"inverted"}
                        onClick={() =>
                            navigate(
                                `${routes.customers(
                                    allowedMunicipalities[0].code
                                )}`,
                                { replace: true }
                            )
                        }
                        title="Gå till min organisation"
                    />
                    <Button
                        theme={"greenDark"}
                        onClick={signout}
                        title="Logga ut"
                    />
                </>
            )}
        </MunicipalityBaseView>
    );
};

export default MunicipalityAccessDenied;
