import React from "react";
import { AuthUserProfile } from "../common/types";

export interface AuthContextObject
    extends Omit<AuthUserProfile, "authorisedUnits"> {
    identityId: string;
    municipalityAuthorisedUnitCodesMap: Record<string, string[]>;
}

export const AuthenticatedUserContext = React.createContext<{
    setAuthUserData: React.Dispatch<
        React.SetStateAction<AuthContextObject | undefined>
    >;
    authUserData?: AuthContextObject;
}>({
    setAuthUserData: () => null,
});
