import { useParams } from "react-router-dom";

const useSelectedMunicipalityId = () => {
    const params = useParams<{ municipalityId: string }>();

    if (!params.municipalityId) {
        throw new Error("No selected municipality id");
    }

    return params.municipalityId;
};

export default useSelectedMunicipalityId;
