import React, { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthenticatedUserContext } from "../contexts/AuthenticatedUserContext";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { routes } from "../routes";
import Loader from "./Loader";

const AdminAuthorized = (props: PropsWithChildren<{}>) => {
    const { authUserData } = useContext(AuthenticatedUserContext);
    const municipalityId = useSelectedMunicipalityId();

    const isAuthorized =
        authUserData && authUserData.municipalityRole === "ADMIN";

    if (isAuthorized === true) {
        return <>{props.children}</>;
    }

    if (isAuthorized === false) {
        return <Navigate to={routes.customers(municipalityId)} replace />;
    }

    return <Loader fullHeight />;
};

export default AdminAuthorized;
