import React, { useContext, useEffect, useState } from "react";
import { userMethods } from "../api/apiMethods";
import { identityConfig } from "../auth/authConst";
import { AuthContext } from "../auth/authProvider";
import { genericErrorConfig } from "../common/exceptions";
import {
    AuthContextObject,
    AuthenticatedUserContext,
} from "../contexts/AuthenticatedUserContext";
import { Logger } from "../utils/logger/Logger";
import Button from "./Button";
import { ModalContext } from "./Modal";

const UserContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { signout } = useContext(AuthContext);
    const { setModalOptions } = useContext(ModalContext);
    const [authUserData, setAuthUserData] = useState<AuthContextObject>();

    useEffect(() => {
        const session = `oidc.user:${identityConfig.authority}:${identityConfig.client_id}`;
        const storageObj = sessionStorage.getItem(session);

        if (!storageObj) {
            return;
        }

        const userId = JSON.parse(storageObj)["profile"]["sub"] as string;
        userMethods()
            .getAuthenticatedUserProfile()
            .then((res) => {
                setAuthUserData({
                    ...res,
                    identityId: userId,
                    municipalityAuthorisedUnitCodesMap:
                        res.authorisedMunicipalities.reduce((acc, curr) => {
                            acc[curr.code] =
                                curr.authorisedUnits?.map((au) => au.code) ||
                                [];
                            return acc;
                        }, {} as Record<string, string[]>),
                });
            })
            .catch((error: Response) => {
                if (error.status === 401) {
                    setModalOptions({
                        title: "Du är inte behörig att nyttja Ombudshandel Online",
                        subtitle:
                            "Prova att logga ut och sedan in igen om du har blivit automatiskt inloggad, så att det är säkert att du använder rätt konto. Om det inte fungerar och du tycker att du borde ha behörighet att logga in, ta kontakt med din kontaktperson hos din kommun.",
                        visible: true,
                        lockDismiss: true,
                        buttons: [
                            <Button
                                size="small"
                                title="Logga ut"
                                onClick={() => {
                                    signout();
                                }}
                            />,
                        ],
                    });
                } else {
                    setModalOptions(
                        genericErrorConfig(
                            () => {
                                setModalOptions({ visible: false });
                                signout();
                            },
                            "Din inloggningssession har löpt ut.",
                            "Vi skickar dig till inloggningssidan."
                        )
                    );
                }

                Logger.error({ userId, error });
            });
    }, [setModalOptions, signout]);

    useEffect(() => {}, []);

    return (
        <AuthenticatedUserContext.Provider
            value={{
                authUserData,
                setAuthUserData,
            }}>
            {children}
        </AuthenticatedUserContext.Provider>
    );
};

export default UserContextProvider;
