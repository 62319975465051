import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { customerMethods } from "../api/apiMethods";
import { useQueryErrorHandling } from "../hooks/useQueryErrorHandling";
import useSelectedMunicipalityId from "../hooks/useSelectedMunicipalityId";
import { queryKeys } from "./queryKeys";

export const useAllCustomersQuery = (
    page: number,
    pageSize: number,
    selectedUnitIds?: string[],
    searchQuery?: string
) => {
    const municipalityId = useSelectedMunicipalityId();

    const query = useQuery({
        queryKey: queryKeys.customer.all(
            municipalityId,
            page,
            pageSize,
            selectedUnitIds,
            searchQuery
        ),
        queryFn: () => {
            if (!municipalityId) {
                throw new Error("No municipality Id");
            }

            try {
                const response = customerMethods(
                    municipalityId
                ).getAllCustomers(page, pageSize, selectedUnitIds, searchQuery);

                return response;
            } catch (error: any) {
                throw new Error(error.message);
            }
        },
        select: (data) => ({
            userList: data?.userlist.filter(
                (item) => !item.deactivationDate?.length
            ),
            pagination: data?.pagination,
        }),
        // staleTime: 1000 * 60 * 5, enable stale time only when we have mutations for Customers and query invalidation on mutation's success
        placeholderData: keepPreviousData,
    });

    useQueryErrorHandling(query, "Kunde inte hämta slutkunder.");

    return query;
};
