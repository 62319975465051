import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import assets from "../../common/assets";
import { genericErrorConfig } from "../../common/exceptions";
import { space } from "../../common/variables";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Cell, { CellHeading } from "../../components/Grid/Cell";
import CellBlock, {
    CellBlockFooter,
    CellBlockSection,
    CellBlockTitle,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import Grid from "../../components/Grid/Grid";
import Loader from "../../components/Loader";
import { ModalContext } from "../../components/Modal";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import TimeFrameList from "../../components/TimeFrameList";
import useSelectedMunicipalityId from "../../hooks/useSelectedMunicipalityId";
import { useCreateUnitMutation } from "../../mutations/useCreateUnitMutation";
import { useMunicipalityRestrictionsQuery } from "../../queries/useMunicipalityRestrictionsQuery";
import { routes } from "../../routes";
import { Logger } from "../../utils/logger/Logger";
import UnitInfoForm from "./UnitInfoForm";
import { useSelectedTimeFrames, useUnitInfoForm } from "./UnitView.hooks";
import { convertMunicipalityRestrictionsFromOutput } from "./unitDetailsConfig";

const CreateUnitView = () => {
    const navigate = useNavigate();
    const { setModalOptions } = useContext(ModalContext);
    const municipalityId = useSelectedMunicipalityId();

    const [isEditingTimeFrames, setIsEditingTimeFrames] = useState(false);

    const { selectedTimeFrames, updateSelectedTimeframes } =
        useSelectedTimeFrames();

    const municipalityRestrictionsQuery = useMunicipalityRestrictionsQuery();

    const availableTimeFrames = municipalityRestrictionsQuery.isSuccess
        ? convertMunicipalityRestrictionsFromOutput(
              municipalityRestrictionsQuery.data
          )
        : [];

    const form = useUnitInfoForm();

    const createUnitMutation = useCreateUnitMutation({
        onSuccess: (unitId: string) => {
            navigate(routes.unitById(municipalityId, unitId), {
                replace: true,
            });
        },
        onError: (error) => {
            Logger.error(error);
            setModalOptions(
                genericErrorConfig(
                    () => setModalOptions({ visible: false }),
                    "Kunde inte spara uppgifterna."
                )
            );
        },
    });

    const mutationIsLoading = createUnitMutation.isPending;

    return (
        <Container>
            <SubHeader backArrow title={"Skapa ny enhet"} />
            <Plate padding="large" spaceBottom={true}>
                {mutationIsLoading ? (
                    <Loader />
                ) : (
                    <Grid gutter={space.padding}>
                        <Cell
                            gutter={space.padding}
                            extraPaddingRight={space.xLarge}>
                            <CellHeading>Information</CellHeading>
                            <CellBlock
                                active
                                noOutlines={true}
                                style={{ marginBottom: "20px" }}>
                                <UnitInfoForm
                                    {...form}
                                    isNewForm={true}
                                    isSubmitLoading={mutationIsLoading}
                                    onCancel={() => navigate(-1)}
                                    onSubmit={(data) => {
                                        createUnitMutation.mutate({
                                            input: data,
                                            selectedTimeFrames,
                                        });
                                    }}
                                />
                            </CellBlock>
                        </Cell>
                        <Cell
                            gutter={space.padding}
                            extraPaddingLeft={space.xLarge}>
                            <CellHeading>Tidsfönster</CellHeading>

                            {isEditingTimeFrames ? (
                                <CellBlock
                                    onEdit={() => setIsEditingTimeFrames(false)}
                                    active>
                                    <>
                                        <CellBlockSection>
                                            <CellBlockTitle>
                                                Ändra tidsfönster
                                            </CellBlockTitle>
                                            <CellBlockValue>
                                                Välj vilka leveranstider som ska
                                                vara tillgängliga för enheten.
                                            </CellBlockValue>
                                        </CellBlockSection>
                                        <CellBlockSection>
                                            <TimeFrameList
                                                data={availableTimeFrames}
                                                overflowValue={space.medium}
                                                setChoice={
                                                    updateSelectedTimeframes
                                                }
                                                selectedData={
                                                    selectedTimeFrames
                                                }
                                            />
                                        </CellBlockSection>
                                        <CellBlockFooter
                                            isLoading={mutationIsLoading}
                                            onCancel={() =>
                                                setIsEditingTimeFrames(false)
                                            }
                                            disableSubmit={
                                                !form.formState.isValid
                                            }
                                        />
                                    </>
                                </CellBlock>
                            ) : (
                                <Button
                                    outsideTitle="Lägg till tidsfönster"
                                    shape="round"
                                    icon={assets.plus}
                                    onClick={() => setIsEditingTimeFrames(true)}
                                />
                            )}
                        </Cell>
                    </Grid>
                )}
            </Plate>
        </Container>
    );
};

export default CreateUnitView;
